import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useUpdateGdprConsent = ({ user }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/update/${user}/gdprConsent`, [user]);

  return useRequest({
    endpoint,
    method: 'POST',
    externalTrigger: true,
  });
};
