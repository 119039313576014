import { useState, useMemo, useCallback } from 'react';
import { Grid, Box, Typography, Skeleton, Link } from '@mui/material';
import * as R from 'ramda';

import { FormText } from '../../shared/form-fields/FormText';
import { FormSelect } from '../../shared/form-fields/FormSelect';
import { useCustomers } from '../../../utils/hooks/useCustomers';

const orderConfig = [
  {
    key: 'first',
    values: [
      { key: 'name', label: 'Deal name', multiline: true, rows: 2 },
      { key: 'customer', label: 'Customer', id: 'customerId', type: 'select', search: true },
      { key: 'location', label: 'Location', required: true },
      { key: 'workModel', label: 'Work model', type: 'select' },
      { key: 'maximumPrice', label: 'Maximum price' },
      { key: 'assignmentId', label: 'Assignment ID' },
      {
        key: 'lastDayForApplication',
        label: 'Last day for application',
        type: 'date',
      },
      { key: 'lastDayForBid', label: 'Last day for bid', type: 'date', required: true },
      {
        key: 'lastDayForQuestions',
        label: 'Last day for questions',
        type: 'date',
      },
      { key: 'scopeOfWork', label: 'Scope of work' },
    ],
  },
  {
    key: 'second',
    values: [
      {
        key: 'frameworkAgreement.name',
        label: 'Framework agreement',
        type: 'select',
      },
      {
        key: 'frameworkAgreement.contractingPartySupplier',
        label: 'Contracting party supplier',
        disabled: true,
      },
      { key: 'role', label: 'Role' },
      { key: 'competenceLevel', label: 'Competence level' },
      { key: 'agreementTerm', label: 'Contract period' },
      { key: 'extensionOption', label: 'Extension option' },
      { key: 'typeOfRequest', label: 'Type of request', type: 'select' },
      { key: 'securityAgreement', label: 'Security agreement' },
      {
        key: 'dataProcessingAgreement',
        label: 'Data processing agreement',
      },
      { key: 'hubspotId', label: 'Hubspot' },
      {
        key: 'publishInBusinessPortal',
        label: 'Publish in Business portal',
        type: 'select',
        required: true,
      },
    ],
  },
];

const getKey = (item) => item.id || item.key;

const getTransformedValue = (item) =>
  R.pipe(R.pathOr('', item.key.split('.')), item.transform || R.identity);

export const EditDealList = ({
  data = {},
  dealData = {},
  formParams,
  loading,
  currentCustomer,
  ...other
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [offset, setOffset] = useState();
  const {
    loading: CustomerLoading,
    result: customers = [],
    error: fetchError,
  } = useCustomers({ offset, searchQuery });

  const newOffset = useMemo(() => {
    if (customers) {
      return customers.paging?.next?.after || null;
    }
    return undefined;
  }, [customers]);

  const customerList = useMemo(
    () =>
      R.not(R.isNil(customers)) && customers.results
        ? customers.results.map((customer) => {
            return {
              key: customer.id,
              name: customer.properties.name,
            };
          })
        : [],
    [customers],
  );

  const customerListUpdated = useMemo(
    () =>
      R.ifElse(
        R.find(R.propEq('key', currentCustomer.customerId)),
        R.identity,
        R.append({ key: currentCustomer.customerId, name: currentCustomer.customer }),
      )(customerList),
    [customerList, currentCustomer],
  );

  const loadMoreCompany = useCallback(() => {
    setOffset(newOffset);
  }, [newOffset]);

  const handleSearchChange = useCallback((value) => {
    setSearchQuery(value);
  }, []);

  return (
    <Grid container item xs={12} spacing={3} sx={{ p: 2, pl: 0 }}>
      {orderConfig.map((config) => (
        <Grid item xs={6} key={config.key}>
          {config.values.map((item) => {
            return (
              <Box key={item.key} sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {item.label} {item.required && <span style={{ color: 'red' }}>*</span>}
                </Typography>
                <Typography variant="body2">
                  {loading ? (
                    <Skeleton width={`${10 + Math.floor(Math.random() * 90)}%`} />
                  ) : item.label === 'Framework agreement' && data.frameworkAgreement ? (
                    <FormSelect
                      valueId={item.id ? R.prop(item.id, data) : item.key}
                      itemKey={getKey(item)}
                      value={data.frameworkAgreement.hubspotId}
                      data={dealData}
                      formParams={formParams}
                      link="true"
                    />
                  ) : item.label === 'Hubspot' && data.frameworkAgreement ? (
                    <Link
                      href={`https://app.hubspot.com/contacts/2215115/record/0-3/${data.hubspotId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      View in Hubspot
                    </Link>
                  ) : item.type === 'select' ? (
                    <FormSelect
                      valueId={item.id ? R.prop(item.id, data) : item.key}
                      itemKey={getKey(item)}
                      value={getTransformedValue(item)(data)}
                      data={dealData}
                      customerList={customerListUpdated}
                      formParams={formParams}
                      hasMoreCompany={R.not(R.isNil(newOffset))}
                      loadMoreCompany={loadMoreCompany}
                      companyOffset={newOffset}
                      loading={R.not(R.all(R.equals(false))([loading, CustomerLoading]))}
                      search={item.search}
                      searchQuery={searchQuery}
                      handleSearchChange={handleSearchChange}
                      {...other}
                    />
                  ) : (
                    <FormText
                      name={getKey(item)}
                      label={item.label}
                      type={item.type}
                      control={formParams.control}
                      defaultValue={getTransformedValue(item)(data)}
                      disabled={item.disabled}
                      required={item.required}
                      hasMoreCompany={R.not(R.isNil(newOffset))}
                      loadMoreCompany={loadMoreCompany}
                      companyOffset={newOffset}
                      edit
                      {...other}
                      {...item}
                    />
                  )}
                </Typography>
              </Box>
            );
          })}
        </Grid>
      ))}
    </Grid>
  );
};
