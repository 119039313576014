import { Typography, Grid, Box, Skeleton, Stack } from '@mui/material';

import { FileDownload } from '../../shared/FileDownload';
import { DownloadAll } from '../../shared/DownloadAll';

const config = [
  { key: 'name', label: 'Name' },
  { key: 'contractingPartySupplier', label: 'Contracting Party Supplier' },
  { key: 'frameworkAgreementId', label: 'Framework Agreement ID' },
  { key: 'contractingPartyCustomer', label: 'Contracting Party Customer' },
  { key: 'contractEndDate', label: 'Contract end date' },
];

export const DetailsText = ({ loading, result, loadingFiles, files }) => {
  const segments = [
    {
      title: 'Details',
      content: config.map((row) => (
        <Grid key={row.key} container item>
          <Grid item xs={5} sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {row.label}
            </Typography>
          </Grid>
          <Grid item xs={7} sx={{ mb: 2 }}>
            <Typography variant="body1">
              {loading ? (
                <Skeleton width={`${5 + Math.floor(Math.random() * 50)}%`} />
              ) : (
                result[row.key] || '-'
              )}
            </Typography>
          </Grid>
        </Grid>
      )),
    },
    {
      title: 'Description',
      content: loadingFiles ? (
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Skeleton width="90%" />
          <Skeleton width="82%" />
          <Skeleton width="40%" />
        </Stack>
      ) : (
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          {result.description || 'There is no description.'}
        </Typography>
      ),
    },
    {
      title: 'Attachments',
      content: loadingFiles ? (
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Skeleton width="45%" />
          <Skeleton width="35%" />
          <Skeleton width="40%" />
        </Stack>
      ) : files.length > 0 ? (
        <>
          {files.map((file, i) => (
            <FileDownload
              key={`${file.name + i}`}
              fileName={file.name}
              url={file.url}
              onError={() => console.log('error')}
            />
          ))}
          <DownloadAll loading={loadingFiles} files={files} label="Framework agreement" />
        </>
      ) : (
        'There are no attachments.'
      ),
    },
  ];
  return (
    <Box>
      {segments.map((segment) => (
        <Box key={segment.title} sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            {segment.title}
          </Typography>
          {segment.content}
        </Box>
      ))}
    </Box>
  );
};
