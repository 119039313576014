import { useCallback, useEffect } from 'react';
import { Button, CircularProgress, Typography, Link } from '@mui/material';

import { useUpdateGdprConsent } from '../../utils/hooks/useUpdateGdprConsent';
import { setWithExpiry } from '../../utils/utils';

export const ConsentBar = ({ style = {}, user, hideGdprMessage }) => {
  const { loading, result = {}, triggerFunc } = useUpdateGdprConsent({ user });

  useEffect(() => {
    if (result.status && result.status === 'success') {
      hideGdprMessage();
      setWithExpiry('not expired');
    }
  }, [result, hideGdprMessage]);

  const handleAccept = useCallback(() => {
    triggerFunc();
  }, [triggerFunc]);

  const hideConsentBar = result && result.status === 'success';

  return (
    !hideConsentBar && (
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: style.backgroundColor,
          color: '#fff',
          padding: '10px',
          textAlign: 'center',
          zIndex: 1000,
        }}
      >
        <Typography variant="caption" sx={{ fontSize: 14 }}>
          By accepting, I confirm that I have read and approved Knowit&apos;s{' '}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.knowit.se/globalassets/policies/kab_0239_eng_external_privacy_policy.pdf"
          >
            External Privacy Policy
          </Link>
        </Typography>
        <Button
          onClick={(event) => handleAccept(event)}
          disabled={loading}
          style={{
            marginLeft: '10px',
            padding: '5px 10px',
            backgroundColor: style.button.backgroundColor,
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Accept
          {loading && (
            <CircularProgress
              size={22}
              thickness={6}
              sx={{
                position: 'absolute',
                top: '25%',
                left: '43%',
              }}
            />
          )}
        </Button>
      </div>
    )
  );
};
