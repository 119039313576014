/* eslint-disable complexity */
import React, { useCallback, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as R from 'ramda';
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  alpha,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { isValid } from 'date-fns';

import { useDealData } from '../../../utils/hooks/useDealData';
import { ColorBanner } from '../../shared/ColorBanner';
import { useEditContents } from './EditContents';
import { ViewLoadError } from '../../shared/ViewLoadError';
import { useUpdateDeal } from '../../../utils/hooks/useUpdateDeal';

const publishInBusinessPortalList = [
  { name: 'Yes', key: 'true' },
  { name: 'No', key: 'false' },
];

export const EditDeal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { result, files = [] } = location.state;
  const { id: dealId } = useParams();
  const theme = useTheme();

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { isDirty, errors },
    setError,
  } = useForm();
  const body = watch();

  const { loading, result: dealData = [], error: fetchError } = useDealData({ dealId });

  const { loading: saving, result: updateResult, triggerFunc } = useUpdateDeal({ dealId, body });

  useEffect(() => {
    const isUpdated = typeof updateResult === 'object' && isValid(new Date(updateResult.updatedAt));
    if (isUpdated) navigate(`/assignments/${dealId}`, { replace: true });
  }, [updateResult, navigate, dealId]);

  const workmodelList =
    dealData && dealData.work_models
      ? dealData.work_models.map((model) => ({
          name: model.label,
          key: model.value,
        }))
      : [];

  const mobileMode = useMediaQuery(theme.breakpoints.down('md'));
  const getColor = (index) => {
    const colors = [alpha(theme.palette.secondary.main, 0.15), theme.palette.background.main];
    if (mobileMode) return colors[R.modulo(index, 2)];
    return colors[R.modulo(Math.ceil(index / 2), 2)];
  };

  const publishInBusinessPortalValue = watch('publishInBusinessPortal');
  const onHandleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (publishInBusinessPortalValue === 'true') {
        triggerFunc();
      } else {
        setError(
          'publishInBusinessPortal',
          {
            type: 'custom',
            message: 'It is required to choose Yes to publish the deal.',
          },
          { shouldFocus: true },
        );
      }
    },
    [triggerFunc, publishInBusinessPortalValue, setError],
  );

  const backToPage = useCallback(() => {
    navigate(`/assignments/${dealId}`);
  }, [navigate, dealId]);

  const contents = useEditContents({
    ...{
      result,
      files,
      dealData: {
        ...dealData,
        workmodelList: [...workmodelList],
        typeOfRequest: dealData.type_of_request || [],
        publishInBusinessPortalList: [...publishInBusinessPortalList],
      },
      edit: true,
      errors,
      formParams: { control, setValue, watch },
      currentCustomer: { customerId: result.customerId, customer: result.customer },
      loading,
    },
  });
  return fetchError ? (
    <ViewLoadError {...{ error: fetchError }} />
  ) : (
    <>
      <ColorBanner
        title={result.customerId || 'Unknown customer'}
        subtitle={result.name || 'Unknown assignment name'}
        loading={loading || saving}
      />
      <Box component="form" onSubmit={(e) => handleSubmit(onHandleSubmit(e))}>
        <Grid container sx={{ mt: 3, pb: 4 }}>
          {contents.map((content, i) => {
            return (
              content && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ p: 4, background: getColor(i) }}
                    key={content.title}
                  >
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {content.title}
                    </Typography>
                    {content.content}
                  </Grid>
                  {content.title === 'Tender files' && (
                    <Grid
                      container
                      justifyContent="center"
                      spacing={1}
                      sx={{ p: 1, background: getColor(i + 1) }}
                    >
                      <Grid item xs={3}>
                        <Box sx={{ position: 'relative', mb: 4, mt: 4, width: 190, height: 40 }}>
                          <Button
                            variant="contained"
                            onClick={backToPage}
                            sx={{ width: 190, height: 40, borderRadius: 6 }}
                            disabled={loading || saving}
                          >
                            Back
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box sx={{ position: 'relative', mb: 4, mt: 4, width: 190, height: 40 }}>
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{ width: 190, height: 40, borderRadius: 6 }}
                            disabled={isDirty === false || loading || saving}
                          >
                            {saving ? 'Saving' : 'Save'}
                          </Button>
                          {saving && (
                            <CircularProgress
                              size={22}
                              thickness={6}
                              sx={{
                                position: 'absolute',
                                top: '25%',
                                left: '43%',
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </>
              )
            );
          })}
        </Grid>
      </Box>
    </>
  );
};
