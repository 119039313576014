import { useRouteCheck } from '../../utils/hooks/useRouteCheck';
import { ConsentBar } from './ConsentBar';

export const RouteMsg = ({ style }) => {
  const { user, showGdprMessage, hideGdprMessage } = useRouteCheck();

  return (
    showGdprMessage && <ConsentBar user={user} style={style} hideGdprMessage={hideGdprMessage} />
  );
};
