import React, { useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import * as R from 'ramda';
import { Box, Button, Grid, Typography, useTheme, alpha, useMediaQuery } from '@mui/material';

import { ApplyDealButton } from './ApplyDealButton';
import { ViewLoadError } from '../../shared/ViewLoadError';
import { ColorBanner } from '../../shared/ColorBanner';
import { Notices } from './Notices';

import { useContents } from './contents';
import { useDeal } from '../../../utils/hooks/useDeal';
import { appUrl } from '../../../utils/utils';

const initialAlertState = { message: '', open: false };

const encodeText = (value) => encodeURIComponent(value);

export const DealDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { accounts } = useMsal();
  const email = accounts[0]?.username || '';

  const [alert, setAlert] = useState(initialAlertState);

  const [message, setMessage] = useState('');
  const handleComment = (event) => {
    setMessage(event.target.value);
  };

  const { id: dealId } = useParams();

  const { loading, result, loadingFiles, files, error: fetchError } = useDeal({ dealId });
  const customUrl = useMemo(() => {
    return {
      url: appUrl(
        `/assignments/${dealId}/${encodeText(result.customer)}/${encodeText(result.name)}`,
      ),
      text: `.../assignments/${dealId}/${encodeText(result.customer)}/${encodeText(result.name)}`,
    };
  }, [result, dealId]);
  const mobileMode = useMediaQuery(theme.breakpoints.down('md'));
  const getColor = (index) => {
    const colors = [alpha(theme.palette.secondary.main, 0.15), theme.palette.background.main];
    if (mobileMode) return colors[R.modulo(index, 2)];
    return colors[R.modulo(Math.ceil(index / 2), 2)];
  };
  const handleEditClick = useCallback(() => {
    navigate(`../assignments/${dealId}/edit`, { state: { result, files } });
  }, [result, navigate, files, dealId]);

  const onError = useCallback((error) => {
    setAlert({ message: error, open: true, variant: 'error' });
  }, []);

  const contents = useContents({
    ...{ result, loading, loadingFiles, files, customUrl, handleComment },
  });
  return fetchError ? (
    <ViewLoadError {...{ error: fetchError }} />
  ) : (
    <>
      <ColorBanner
        title={result.customer || 'Unknown customer'}
        subtitle={result.name || 'Unknown assignment name'}
        loading={loading}
      />
      <Notices {...{ result }} />
      <Grid container sx={{ mt: 3, pb: 4 }}>
        {contents.map((content, i) => {
          return (
            <Grid item xs={12} md={6} sx={{ p: 4, background: getColor(i) }} key={content.title}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {content.title}
              </Typography>
              {content.content}
              <Grid container>
                <Grid item xs={6}>
                  {content.title === 'Register interest' && (
                    <ApplyDealButton
                      dealId={Number(dealId)}
                      body={message}
                      status={result.status || 'loading'}
                      bidDue={result.lastDayForBid}
                      onError={onError}
                      loadingDeal={loading}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {content.title === 'Register interest' &&
                    R.equals(email, result.hubspotOwnerEmail) && (
                      <Box sx={{ position: 'relative', mb: 4, mt: 4, width: 190, height: 40 }}>
                        <Button
                          variant="contained"
                          sx={{ width: 190, height: 40, borderRadius: 6 }}
                          disabled={
                            R.not(R.equals(email, result.hubspotOwnerEmail)) ||
                            loading ||
                            loadingFiles
                          }
                          onClick={handleEditClick}
                        >
                          Edit
                        </Button>
                      </Box>
                    )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
