import React from 'react';
import { Box, Button } from '@mui/material';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const DownloadAll = ({ loading, files, label }) => {
  const downloadAllFilesAsZip = async () => {
    const zip = new JSZip();
    const folder = zip.folder('All files');
    const mimeTypesToExtensions = {
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'text/plain': 'txt',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'image/gif': 'gif',
      'image/bmp': 'bmp',
      'image/webp': 'webp',
      'image/svg+xml': 'svg',
      'image/tiff': 'tiff',
      'audio/mpeg': 'mp3',
      'audio/wav': 'wav',
      'audio/ogg': 'ogg',
      'audio/aac': 'aac',
      'audio/flac': 'flac',
      'video/mp4': 'mp4',
      'video/mpeg': 'mpeg',
      'video/webm': 'webm',
      'video/ogg': 'ogv',
      'video/avi': 'avi',
      'video/quicktime': 'mov',
      'text/html': 'html',
      'text/css': 'css',
      'text/javascript': 'js',
      'application/json': 'json',
      'application/xml': 'xml',
      'application/zip': 'zip',
      'application/x-rar-compressed': 'rar',
      'application/x-7z-compressed': '7z',
      'application/x-tar': 'tar',
      'application/gzip': 'gz',
      'application/octet-stream': 'bin',
      'application/x-shockwave-flash': 'swf',
      'application/java-archive': 'jar',
    };

    const fetchAndAddToZip = async (file) => {
      const response = await fetch(file.url);
      const arrayBuffer = await response.arrayBuffer();
      const mimeType = response.headers.get('Content-Type');

      const extension = mimeTypesToExtensions[mimeType] || 'bin';

      const fileNameWithExtension = `${file.name}.${extension}`;
      const fileBlob = new Blob([arrayBuffer], { type: mimeType });
      folder.file(fileNameWithExtension, fileBlob);
    };

    await Promise.all(files.map((file) => fetchAndAddToZip(file)));

    zip
      .generateAsync({ type: 'blob' })
      .then((content) => {
        saveAs(content, `${label}_files.zip`);
      })
      .catch((error) => {
        console.error('Failed to generate zip file to download', error);
      });
  };

  return (
    files.length > 1 && (
      <Box sx={{ position: 'relative', mb: 4, mt: 4, width: 190, height: 40 }}>
        <Button
          variant="contained"
          sx={{ width: 190, height: 40, borderRadius: 6 }}
          disabled={loading}
          onClick={downloadAllFilesAsZip}
        >
          Download all
        </Button>
      </Box>
    )
  );
};
