import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useDealData = ({ dealId, offset = 0 }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/deals/${dealId}/data`, [dealId]);
  return useRequest({
    endpoint,
    method: 'POST',
    body: offset,
  });
};
