import React, { useState, useMemo } from 'react';
import { Box, Grid } from '@mui/material';

import { Tabs, Tab } from '../../shared/table/Tabs';
import { DealsTableView } from './DealsTableView';
import { SelectUserCountry } from '../../shared/SelectUserCountry';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`deals-tabpanel-${index}`}
      aria-labelledby={`deals-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const DealsTable = () => {
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const UserCountryComp = useMemo(
    () => (
      <SelectUserCountry
        countryList={countryList}
        currentCountry={selectedCountry}
        setCountry={setSelectedCountry}
      />
    ),
    [selectedCountry, countryList],
  );

  return (
    <Grid container>
      <Grid item xs={2} md={16}>
        <Grid container>
          <Grid item xs={10}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="Dashboard tabs"
            >
              <Tab value={0} label="Available" />
              <Tab value={1} label="Archived" />
              <Tab
                value={2}
                label="Registration"
                title="Assignments with pending mandatory fields"
              />
            </Tabs>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end">
              {UserCountryComp}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} md={16}>
        <TabPanel value={tabIndex} index={0}>
          <DealsTableView setCountryList={setCountryList} selectedCountry={selectedCountry} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <DealsTableView
            archived
            setCountryList={setCountryList}
            selectedCountry={selectedCountry}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <DealsTableView
            registration
            setCountryList={setCountryList}
            selectedCountry={selectedCountry}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};
