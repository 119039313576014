import * as React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumbs as MuiBreadcrumbs, Box, Container, Typography } from '@mui/material';

import { BreadcrumbLink } from './BreadcrumbLink';

export const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();
  return (
    <Container>
      <Box
        role="presentation"
        display={breadcrumbs.length > 1 ? 'block' : 'none'}
        sx={{ p: 1.75, pl: 0 }}
      >
        <MuiBreadcrumbs variant="caption">
          {breadcrumbs.map((b, i) =>
            i < breadcrumbs.length - 1 ? (
              <BreadcrumbLink
                path={b.key}
                breadcrumb={decodeURIComponent(b.breadcrumb.props.children)}
                key={b.key}
              />
            ) : (
              <Typography variant="caption" sx={{ fontWeight: 'bold' }} key={b.key}>
                {decodeURIComponent(b.breadcrumb.props.children)}
              </Typography>
            ),
          )}
        </MuiBreadcrumbs>
      </Box>
    </Container>
  );
};
