import { useState, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { Table } from '../../shared/table/Table';
import { dealsColumns } from './dealsColumns';

import { useDeals } from '../../../utils/hooks/useDeals';

export const DealsTableView = ({
  archived = false,
  registration = false,
  selectedCountry,
  setCountryList,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [density, setDensity] = useState(null);
  const {
    loading,
    result = {},
    error,
  } = useDeals(archived ? { archived } : registration ? { registration } : {});
  const { results: deals = [], total } = result;
  const columns = archived ? dealsColumns({ archived: true, density }) : dealsColumns({ density });

  const filterDealsByCountry = useMemo(
    () => deals.filter((deal) => selectedCountry.includes(deal.country)),
    [deals, selectedCountry],
  );

  useEffect(() => {
    const countryList = R.reject(
      R.either(R.isEmpty, R.isNil),
      R.uniq(R.map(R.prop('country'))(deals)),
    );
    if (deals.length > 0) setCountryList([...countryList]);
  }, [deals, setCountryList]);

  return (
    <Table
      data={R.sortWith([R.descend(R.prop('created'))])(
        selectedCountry.includes('All') || selectedCountry.length === 0
          ? deals
          : filterDealsByCountry,
      )}
      searchRowEvolveMap={{ frameworkAgreement: R.prop('name'), created: R.always('') }}
      onStateChange={(table) => density !== table.density.value && setDensity(table.density.value)}
      filterSwitchSearchText="applied"
      filterSwitchLabel="Only yours"
      cols={columns}
      page={page}
      setPage={setPage}
      size={pageSize}
      setSize={setPageSize}
      loading={loading}
      error={error}
      rowCount={archived ? total : undefined}
      getRowId={(row) => row.hubspotId}
      clickable
    />
  );
};
