import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { Home } from './components/pages/home/Home';
import { HomeGuest } from './components/pages/home-guest/HomeGuest';
import { Deals } from './components/pages/deals/Deals';
import { FrameworkAgreementDetails } from './components/pages/fa-details/FrameworkAgreementDetails';
import { FrameworkAgreements } from './components/pages/fa/FrameworkAgreements';
import { DealDetails } from './components/pages/deal-details/DealDetails';
import { EditDeal } from './components/pages/deal-details/EditDeal';
import { Register } from './components/pages/register/Register';
import { Support } from './components/pages/support/Support';
import { NotFound } from './components/pages/NotFound';

import { AppBar } from './components/shared/AppBar';
import { Breadcrumbs } from './components/shared/Breadcrumbs';
import { RouteMsg } from './components/shared/RouteMsg';
import { Private } from './components/shared/Private';
import { getWithExpiry } from './utils/utils';
import { theme as bpTheme } from './theme';

export const App = () => {
  const theme = responsiveFontSizes(createTheme(bpTheme));
  const gdprConsent = getWithExpiry();

  const style = {
    backgroundColor: theme.palette.border.light,
    button: { backgroundColor: theme.palette.primary.main },
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Router>
        <Routes>
          <Route path="/register" element="" />
          <Route
            path="*"
            element={
              <>
                <AppBar />
                <Breadcrumbs />
              </>
            }
          />
        </Routes>
        <Container position="absolute">
          <AuthenticatedTemplate>
            {gdprConsent === null && <RouteMsg style={style} />}
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route
                exact
                path="/assignments"
                element={
                  <Private>
                    <Deals />
                  </Private>
                }
              />
              <Route
                path="/assignments/:id"
                element={
                  <Private>
                    <DealDetails />
                  </Private>
                }
              />
              <Route
                path="/assignments/:id/:customer/*"
                element={
                  <Private>
                    <DealDetails />
                  </Private>
                }
              />
              <Route
                path="/assignments/:id/edit"
                element={
                  <Private>
                    <EditDeal />
                  </Private>
                }
              />
              <Route
                exact
                path="/framework-agreements"
                element={
                  <Private>
                    <FrameworkAgreements />
                  </Private>
                }
              />
              <Route
                path="/framework-agreements/:id"
                element={
                  <Private>
                    <FrameworkAgreementDetails />
                  </Private>
                }
              />
              <Route path="/register" element={<Navigate to="/" />} />
              <Route path="/support/:section" element={<Support />} />
              <Route path="/support" element={<Support />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<HomeGuest />} />
            </Routes>
          </UnauthenticatedTemplate>
        </Container>
      </Router>
    </ThemeProvider>
  );
};
