import { Grid, Box, Typography, Skeleton, Link } from '@mui/material';
import * as R from 'ramda';

import { CopyLink } from '../../shared/CopyLink';

const orderConfig = [
  {
    key: 'first',
    values: [
      { key: 'name', label: 'Name' },
      { key: 'customer', label: 'Customer' },
      { key: 'location', label: 'Location' },
      { key: 'workModel', label: 'Work model' },
      { key: 'maximumPrice', label: 'Maximum price' },
      { key: 'assignmentId', label: 'Assignment ID' },
      { key: 'lastDayForApplication', label: 'Last day for application' },
      { key: 'lastDayForBid', label: 'Last day for bid' },
      { key: 'lastDayForQuestions', label: 'Last day for questions' },
      { key: 'scopeOfWork', label: 'Scope of work' },
    ],
  },
  {
    key: 'second',
    values: [
      { key: 'frameworkAgreement.name', label: 'Framework agreement' },
      { key: 'frameworkAgreement.contractingPartySupplier', label: 'Contracting party supplier' },
      { key: 'role', label: 'Role' },
      { key: 'competenceLevel', label: 'Competence level' },
      { key: 'agreementTerm', label: 'Contract period' },
      { key: 'extensionOption', label: 'Extension option' },
      { key: 'typeOfRequest', label: 'Type of request' },
      { key: 'securityAgreement', label: 'Security agreement' },
      { key: 'dataProcessingAgreement', label: 'Data processing agreement' },
      { key: 'hubspotId', label: 'Hubspot' },
      { key: 'customLink', label: 'Custom link' },
    ],
  },
];

const getTransformedValue = (item) =>
  R.pipe(R.pathOr('-', item.key.split('.')), item.transform || R.identity);

export const DealDetailsList = ({ data = {}, customUrl, loading }) => {
  return (
    <Grid container item xs={12} spacing={3} sx={{ p: 2, pl: 0 }}>
      {orderConfig.map((config) => (
        <Grid item xs={6} key={config.key}>
          {config.values.map((item) => {
            return (
              <Box key={item.key} sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {item.label}
                </Typography>
                <Typography variant="body2">
                  {loading ? (
                    <Skeleton width={`${10 + Math.floor(Math.random() * 90)}%`} />
                  ) : item.label === 'Framework agreement' && data.frameworkAgreement ? (
                    <Link href={`/framework-agreements/${data.frameworkAgreement.hubspotId}`}>
                      {R.path(item.key.split('.'))(data) || '-'}
                    </Link>
                  ) : item.label === 'Hubspot' && data.frameworkAgreement ? (
                    <Link
                      href={`https://app.hubspot.com/contacts/2215115/record/0-3/${data.hubspotId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      View in Hubspot
                    </Link>
                  ) : item.label === 'Custom link' ? (
                    <CopyLink customUrl={customUrl} />
                  ) : (
                    getTransformedValue(item)(data)
                  )}
                </Typography>
              </Box>
            );
          })}
        </Grid>
      ))}
    </Grid>
  );
};
