import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useCustomers = ({ offset = 0, searchQuery = '' }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/deals/customers/${offset}`, [offset]);
  return useRequest({
    endpoint,
    method: 'POST',
    body: searchQuery,
  });
};
