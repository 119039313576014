import React, { useCallback, useState, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, IconButton, useTheme, styled, Grid } from '@mui/material';
import { Delete, Restore } from '@mui/icons-material/';

import { FileDownload } from '../FileDownload';
import { toNumber } from '../../../utils/utils';

const ParentBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  border: `1px dashed ${theme.palette.border.light}`,
  borderRadius: 4,
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    '& .arrowIcon': {
      width: '50%',
    },
  },
}));

export const Dropzone = ({ name, setValue, existingFiles }) => {
  const theme = useTheme();
  const [myFiles, setMyFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [removeOlddFiles, setRemovedOldFiles] = useState([]);

  const [text, setText] = useState(`${myFiles.length} selected file(s)`);

  useEffect(() => {
    if (existingFiles) {
      setOldFiles((files) => R.pipe(R.concat(existingFiles), R.uniq)(files));
    }
  }, [existingFiles]);

  useEffect(() => {
    const removedList = R.map(R.prop('fileId'))(removeOlddFiles);
    setValue('detachOldFiles', R.map(toNumber, removedList), { shouldDirty: true });
  }, [setValue, removeOlddFiles]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uniqueFiles = R.uniqBy(R.prop('path'))([...myFiles, ...R.uniq(acceptedFiles)]);
      setMyFiles(uniqueFiles);
      setValue('isDirty', true, { shouldDirty: true });
    },
    [myFiles, setValue],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  const removeFile = (file) => {
    const newFiles = R.remove(myFiles.indexOf(file), 1, myFiles);
    setMyFiles(newFiles);
    setText(`${myFiles.length} selected file(s)`);
  };

  const removeExistingFile = (file) => {
    const existingFileList = R.remove(oldFiles.indexOf(file), 1, oldFiles);
    setRemovedOldFiles((files) => (R.indexOf(file, files) === -1 ? R.append(file, files) : files));
    setOldFiles(existingFileList);
    setValue('isDirty', true, { shouldDirty: true });
  };

  const restoreFile = (url) => {
    const deletedFile = R.find(R.pipe(R.prop('url'), R.equals(url)))(removeOlddFiles);
    if (deletedFile) {
      setOldFiles((files) =>
        R.indexOf(deletedFile, files) === -1 ? R.append(deletedFile, files) : files,
      );
      setRemovedOldFiles((files) => R.remove(files.indexOf(deletedFile), 1, files));
    }
  };

  useEffect(() => {
    setValue(name, myFiles);
    setText(`${myFiles.length} selected file(s)`);
  }, [name, setValue, myFiles]);

  const CustomTypography = useMemo(() => {
    return styled(Typography)(() => ({
      textDecoration: 'line-through',
      letterSpacing: '2px',
    }));
  }, []);

  return (
    <>
      <ParentBox
        sx={{ position: 'relative', mb: 2 }}
        {...getRootProps()}
        onMouseOver={() => setText('Click to browse or drag file')}
        onMouseOut={() => setText(`${myFiles.length} selected file(s)`)}
      >
        <input {...getInputProps()} />
        <Typography
          variant="body1"
          color={theme.palette.text.gray600}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {isDragActive ? 'Drop to use this file' : text}
        </Typography>
      </ParentBox>

      {oldFiles.map((file, i) => (
        <Grid container>
          <Grid item xs={4}>
            <Box height={20} width={400} my={4}>
              <FileDownload
                key={`${file.name + i}`}
                fileName={file.name}
                url={file.url}
                onError={() => console.log('FileDownload had an error')}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box height={20} width={20} my={4}>
              <IconButton size="small" onClick={() => removeExistingFile(file)}>
                <Delete sx={{ height: '18px', width: '18px' }} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      ))}

      {removeOlddFiles.map((file) => (
        <Box
          key={file.name}
          sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', mb: 2 }}
        >
          <CustomTypography component="div">
            <Box mt={1} ml={1}>
              {file.name}
            </Box>
          </CustomTypography>
          <IconButton size="small" onClick={() => restoreFile(file.url)}>
            <Restore sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Box>
      ))}

      {myFiles.map((file) => (
        <Box key={file.path} sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
          <Typography variant="body1" display="inline" sx={{ ml: '18px' }}>
            {file.path} - {file.size} bytes
          </Typography>
          <IconButton size="small" onClick={() => removeFile(file)}>
            <Delete sx={{ height: '18px', width: '18px' }} />
          </IconButton>
        </Box>
      ))}
    </>
  );
};
