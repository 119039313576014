import { useTheme } from '@mui/material';

import { Notice } from '../../shared/Notice';
import { timeLeft, valueOrFalse } from '../../../utils/utils';

/* eslint-disable complexity */
export const Notices = ({ result }) => {
  const theme = useTheme();

  const daysLeftForRegister = timeLeft(result.lastDayForApplication, true);
  const daysLeftToNotice = 3;
  const daysLeftBid = timeLeft(result.lastDayForBid, true);
  return valueOrFalse(daysLeftBid) > 0 ? (
    <>
      {result.stage === 'create_proposal' && (
        <Notice
          color={`${theme.palette.flamingo.dark}`}
          message="Work with the bid has started with another party. You can still register interest, but the chance to be assigned is low."
        />
      )}

      {result.stage === 'proposal_presented' && (
        <Notice
          color="leather"
          message="The bid has been sent to the customer. Now its too late to register interest."
        />
      )}

      {result.reserved && (
        <Notice
          color="error"
          message="This assignment is reserved. You can still register interest, but the assignment will likely be assigned to another party."
        />
      )}

      {valueOrFalse(result.status) !== 'applied' &&
        valueOrFalse(daysLeftForRegister) <= daysLeftToNotice && (
          <Notice
            message={
              daysLeftForRegister > 1
                ? `There are only ${daysLeftForRegister} days left to register interest for this assignment on time.`
                : daysLeftForRegister === 1
                ? 'Today is the last day to register interest for this assignment on time.'
                : 'The application date for this assignment has passed. You can still register interest, with lower priority.'
            }
            color="primary"
          />
        )}

      {valueOrFalse(result.status) === 'applied' && (
        <Notice message="You have registered interest for this assignment" />
      )}

      {valueOrFalse(result.interestedParties) &&
        result.interestedParties.length > 0 &&
        result.internalUser && (
          <Notice
            message={`${result.interestedParties.length} other user(s) have registered interest for this assignment.`}
            color={result.interestedParties.length > 1 ? 'error' : 'primary'}
          />
        )}
    </>
  ) : (
    valueOrFalse(daysLeftBid) === 0 && (
      <Notice message="This assignment is no longer open for applications." />
    )
  );
};
