import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export const FormText = ({ name, label, defaultValue = '', control, edit, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          fullWidth
          size="small"
          {...field}
          label={!edit ? label : ''}
          control={control}
          {...other}
        />
      )}
    />
  );
};
