import * as R from 'ramda';
import { isValid, format, parseISO } from 'date-fns';

export const urlParams = (obj) =>
  `?${Object.entries(obj)
    .map((pair) => pair.join('='))
    .join('&')}`;

export const getHost = () => window.location.host;
export const getProtocol = () => window.location.protocol;
export const isLocalhost = () => getHost().startsWith('localhost');
const localhostApiEndpoint = 'http://localhost:8000';

export const getApiUrl = () =>
  isLocalhost() ? localhostApiEndpoint : `${getProtocol()}//${getHost()}/api`;

export const appUrl = (relativePath, params) =>
  `${getProtocol()}//${getHost()}${relativePath || ''}${params ? urlParams(params) : ''}`;

export const timeLeft = (date, includeLast = false, asDays = true) => {
  if (!date) return '';
  const now = new Date(Date.now());
  const end = parseISO(date);
  const timeLeftraw = end - now;
  if (!asDays) return timeLeftraw;
  const daysLeft = Math.ceil(timeLeftraw / 86400000);
  return includeLast ? Math.max(0, daysLeft + 1) : Math.max(0, daysLeft);
};

export const valueOrFalse = R.ifElse(R.isNil, R.always(false), R.identity);

export const customDateFormat = (tenderDate) => {
  if (tenderDate === null) return null;
  const isDate = isValid(new Date(tenderDate));
  if (!isDate) return null;
  const dateFormat = format(parseISO(tenderDate), 'yyyy-MM-dd');
  return dateFormat;
};

export const toNumber = (val) => (Number.isNaN(val) ? val : Number(val));

export const setWithExpiry = (value) => {
  const now = new Date();
  const ttl = 24 * 60 * 60 * 1000;
  const key = 'gdpr_consent';

  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = () => {
  const key = 'gdpr_consent';
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
