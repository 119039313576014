import { Typography, Skeleton, Stack, TextField, Box } from '@mui/material';

import { EditDealList } from './EditDealList';
import { FormText } from '../../shared/form-fields/FormText';
import { FormFile } from '../../shared/form-fields/FormFile';

import { valueOrFalse } from '../../../utils/utils';

// eslint-disable-next-line complexity
export const useEditContents = ({
  result,
  dealData,
  loading,
  loadingFiles,
  edit,
  files,
  handleComment,
  control,
  formParams,
  ...other
}) => [
  {
    title: 'Details',
    content: (
      <EditDealList
        data={result}
        dealData={dealData}
        loading={loading}
        formParams={formParams}
        {...other}
      />
    ),
  },
  {
    title: 'Description',
    content: loading ? (
      <Stack spacing={1} sx={{ mt: 2 }}>
        <Skeleton />
        <Skeleton width="85%" />
        <Skeleton width="95%" />
        <Skeleton width="70%" />
      </Stack>
    ) : edit === true ? (
      <FormText
        name="description"
        label="Description"
        control={formParams ? formParams.control : control}
        defaultValue={result.description || ''}
        sx={{ p: 2, pl: 0, whiteSpace: 'pre-line' }}
        style={{ wordWrap: 'break-word' }}
        multiline
        edit
      />
    ) : (
      <Typography sx={{ p: 2, pl: 0, whiteSpace: 'pre-line' }} style={{ wordWrap: 'break-word' }}>
        {result.description || ''}
      </Typography>
    ),
  },
  {
    title: 'Tender files',
    content: loadingFiles ? (
      <Stack spacing={3} sx={{ mt: 2 }}>
        <Skeleton width="45%" />
        <Skeleton width="35%" />
        <Skeleton width="40%" />
      </Stack>
    ) : (
      <>
        <Box sx={{ position: 'relative', mb: 4, mt: 4 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Link
          </Typography>
          <FormText
            name="tenderDocumentsLink"
            label="Tender doc Link"
            control={formParams ? formParams.control : control}
            defaultValue={result.tenderDocumentsLink || ''}
            sx={{ p: 2, pl: 0, whiteSpace: 'pre-line' }}
            style={{ wordWrap: 'break-word' }}
            multiline
            edit
          />
        </Box>
        <Box sx={{ position: 'relative', mb: 4, mt: 4 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Attachments
          </Typography>
          <FormFile
            {...{ setValue: formParams.setValue }}
            name="applicationFiles"
            label="Add files"
            control={formParams.control}
            hideLabel
            existingFiles={files}
          />
        </Box>
      </>
    ),
  },
  handleComment && {
    title: 'Register interest',
    content: (
      <>
        <Typography sx={{ pt: 2, whiteSpace: 'pre-line' }}>
          After registration, we will contact you. {'\n\n'} If there are more than one interested
          applicant, we will evaluate which one is more qualified.
        </Typography>
        <TextField
          label="Additional information (optional)"
          multiline
          rows={4}
          fullWidth
          size="small"
          onChange={handleComment}
          sx={{ mt: 3 }}
          disabled={valueOrFalse(result.status) === 'applied'}
        />
        {valueOrFalse(result.interestedParties) && result.interestedParties.length > 0 && (
          <Typography sx={{ pt: 2, whiteSpace: 'pre-line' }}>
            {result.internalUser
              ? `Note that these parties: ${result.interestedParties.join(', ')},`
              : 'Other user(s) '}
            have already shown interest in the assignment.
          </Typography>
        )}
      </>
    ),
  },
];
