import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import { Card, Typography, Grid, Box, Slide, Alert, Snackbar, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMsal } from '@azure/msal-react';

import { AppliedMessage } from './AppliedMessage';
import { FormText } from '../../shared/form-fields/FormText';
import { FormFile } from '../../shared/form-fields/FormFile';

import { agreementFormSegments } from '../../../config/config';
import { useApplyFA } from '../../../utils/hooks/useApplyFA';
import { ApplyFAButton } from './ApplyFAButton';

// TODO: Add better error handling
export const AgreementForm = ({ applied = false }) => {
  const theme = useTheme();
  const { accounts } = useMsal();

  const fullname = accounts[0]?.name || '';
  const names = fullname.length > 0 ? fullname.split(' ') : [];
  const email = accounts[0]?.username || '';

  const { id: agreementId } = useParams();
  const { control, setValue, handleSubmit, watch } = useForm();
  const body = watch();
  const [sent, setSent] = useState(applied);

  const onSuccess = () => setSent(true);

  const { loading: sending, result, triggerFunc } = useApplyFA({ agreementId, body });

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const openSnackBar = () => {
    setSnackBarOpen(true);
  };
  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const onHandleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const reference2Object = agreementFormSegments.find(
        (obj) => obj.name === 'Secondary reference',
      );

      const reference2Properties = reference2Object.fields.map((refObj) => refObj.name);
      const reference2Status = reference2Properties.reduce(
        (acc, key) => {
          const valLength = body[key].trim().length;
          if (valLength === 0) return { ...acc, empty: acc.empty + 1 };
          return { ...acc, nonEmpty: acc.nonEmpty + 1 };
        },
        { empty: 0, nonEmpty: 0 },
      );
      if (
        reference2Status.empty === reference2Properties.length ||
        reference2Status.nonEmpty === reference2Properties.length
      ) {
        triggerFunc();
      } else {
        setSnackBarMessage(
          'Secondary reference is optional, either fill in all details or leave it empty',
        );
        openSnackBar();
      }
    },
    [body, triggerFunc],
  );

  useEffect(() => {
    const onError = (message) => {
      setSnackBarMessage(message && message);
      openSnackBar();
    };
    if (!R.isEmpty(result)) {
      const { ok, status: responseStatus, statusText } = result;
      if (!ok && onError) onError(`Registration failed: ${responseStatus} ${statusText}`);
      else if (ok === true) {
        if (onSuccess) onSuccess();
      }
    }
  }, [result]);

  return (
    <>
      <Slide in timeout={700} direction="up">
        <Card
          component="form"
          onSubmit={(e) => handleSubmit(onHandleSubmit(e))}
          sx={{ p: 2, maxWidth: 600, mb: 2, mt: 2 }}
          elevation={4}
        >
          <Box display="flex" sx={{ m: -2, mb: 2, background: theme.palette.primary.main }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: theme.palette.white.main, m: 3, ml: 2 }}
              align="left"
            >
              File your application
            </Typography>
          </Box>
          {!sent && (
            <Grid container spacing={3.5} align="center">
              {agreementFormSegments.map(
                (segment) =>
                  segment.fields.length > 0 && (
                    <Grid key={segment.name} container item xs={segment.xs || 6}>
                      {segment.name && (
                        <Grid item xs={12} sx={{ mb: 2 }} align="left">
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {segment.name}
                          </Typography>
                        </Grid>
                      )}
                      {segment.fields.map((field) => (
                        <Grid key={field.name} item xs={12} sx={{ mb: 2 }}>
                          {segment.name === 'Application files' ? (
                            <FormFile
                              {...{ setValue }}
                              required={field.required}
                              name={field.name}
                              label={field.label}
                              control={control}
                              labelbackgroud={theme.palette.white.main}
                              hideLabel
                              multiline={field.multiline}
                              rows={field.rows}
                            />
                          ) : field.name === 'sharedEmail' ? (
                            <FormText
                              {...field}
                              control={control}
                              defaultValue={email}
                              disabled={R.length(email) > 0}
                            />
                          ) : field.name === 'firstName1' ? (
                            <FormText
                              {...field}
                              control={control}
                              defaultValue={names.length > 0 ? names[0] : ''}
                            />
                          ) : field.name === 'lastName1' ? (
                            <FormText
                              {...field}
                              control={control}
                              defaultValue={names.length > 0 ? names[1] : ''}
                            />
                          ) : field.name === 'email1' ? (
                            <FormText {...field} control={control} defaultValue={email} />
                          ) : (
                            <FormText {...field} control={control} />
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  ),
              )}
              <Grid item xs={12}>
                <ApplyFAButton {...{ sending }} />
              </Grid>
            </Grid>
          )}
          {(applied || sent) && (
            <AppliedMessage {...{ sent, setSent, agreementId: Number(agreementId) }} />
          )}
        </Card>
      </Slide>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity="error" sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
