import { useMemo } from 'react';
import * as R from 'ramda';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useUpdateDeal = ({ dealId, body }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/deal/${dealId}/update`, [dealId]);
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (key === 'applicationFiles') {
      value.forEach((file) => {
        formData.append(key, file);
      });
    } else if (key === 'detachOldFiles') {
      if (value !== undefined && value.length > 0) formData.append(key, value);
    } else if (key === 'frameworkAgreement') {
      Object.entries(value).forEach(([faKey, faValue]) => {
        if (faKey === 'name') formData.append('frameworkAgreementId', faValue);
        else formData.append(faKey, faValue);
      });
    } else if (key === 'customerId') {
      if (R.isNil(value) || R.isEmpty(value)) formData.append(key, 0);
      else formData.append(key, value.key);
    } else {
      formData.append(key, value);
    }
  });
  return useRequest({
    endpoint,
    method: 'POST',
    externalTrigger: true,
    body: formData,
  });
};
