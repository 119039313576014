import { useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

import { useGdprData } from './useGdprData';
import { setWithExpiry } from '../utils';

export const useRouteCheck = () => {
  const { accounts } = useMsal();
  const email = accounts[0]?.username || '';

  const [showGdprMessage, setShowGdprMessage] = useState(false);

  const { loading, result = {} } = useGdprData({ user: email });

  useEffect(() => {
    if (!loading) {
      if (result.status) {
        if (result.status === 'success') {
          const expired = result.gdpr_expired;
          setShowGdprMessage(expired);
          if (expired === false) {
            setWithExpiry('not expired');
          }
        }
        return null;
      }
    }
    return null;
  }, [result, loading]);

  const hideGdprMessage = useCallback(() => setShowGdprMessage(false), []);

  return { showGdprMessage: !loading && showGdprMessage, hideGdprMessage, user: email };
};
