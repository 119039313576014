import React, { useState } from 'react';
import { Box, Typography, Button, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

export const CopyLink = ({ customUrl }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(decodeURIComponent(customUrl.url)).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    });
  };

  return (
    <Box
      display="flex"
      sx={{
        width: '100%',
        padding: 1,
        overflow: 'hidden',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
      }}
    >
      <Typography>{decodeURIComponent(customUrl.text)}</Typography>
      <Tooltip title={copySuccess || 'Copy to clipboard'}>
        <Button onClick={copyToClipboard} size="small" startIcon={<ContentCopy />} />
      </Tooltip>
    </Box>
  );
};
