import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useGdprData = ({ user }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/gdprData/${user}`, [user]);
  return useRequest({
    endpoint,
    method: 'GET',
  });
};
